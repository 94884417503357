<template>
  <div class="content-box">
    <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="6">
                <b-form-group
                  :class="{ row, error: v$.vendor.name.$errors.length }"
                  :label="$t('forms.name')"
                  label-for="name"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input v-model="vendor.name" id="name" />
                  <div
                    class="input-errors"
                    v-for="error of v$.vendor.name.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                  :class="{ row, error: v$.vendor.contactName.$errors.length }"
                  :label="$t('forms.contactName')"
                  label-for="contactName"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input v-model="vendor.contactName" id="contactName" />
                  <div
                    class="input-errors"
                    v-for="error of v$.vendor.contactName.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                  :class="{ row, error: v$.vendor.email.$errors.length }"
                  :label="$t('forms.email')"
                  label-for="email"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input v-model="vendor.email" id="email" />
                  <div
                    class="input-errors"
                    v-for="error of v$.vendor.email.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                  :class="{ row, error: v$.vendor.telephone.$errors.length }"
                  :label="$t('forms.telephone')"
                  label-for="telephone"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input v-model="vendor.telephone" id="telephone" />
                  <div
                    class="input-errors"
                    v-for="error of v$.vendor.telephone.$errors"
                    :key="error.$uid"
                  >
                    <small class="text-danger">{{ error.$message }}</small>
                  </div>
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.contactNameAlt')"
                  label-for="contactNameAlt"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input v-model="vendor.contactNameAlt" id="contactNameAlt" />
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.emailAlt')"
                  label-for="emailAlt"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input v-model="vendor.emailAlt" id="emailAlt" />
                </b-form-group>
                <b-form-group
                  class="row"
                  :label="$t('forms.telephoneAlt')"
                  label-for="telephoneAlt"
                  label-cols-md="4"
                  content-cols-lg="8"
                >
                  <b-form-input v-model="vendor.telephoneAlt" id="telephoneAlt" />
                </b-form-group>
                  <b-form-group
                      :class="{ row, error: v$.vendor.website.$errors.length }"
                      :label="$t('forms.website')"
                      label-for="website"
                      label-cols-md="4"
                      content-cols-lg="8"
                  >
                      <b-form-input v-model="vendor.website" id="website" />
                      <div
                          class="input-errors"
                          v-for="error of v$.vendor.website.$errors"
                          :key="error.$uid"
                      >
                          <small class="text-danger">{{ error.$message }}</small>
                      </div>
                  </b-form-group>
                  <b-form-group
                      class="row"
                      :label="$t('forms.username')"
                      label-for="username"
                      label-cols-md="4"
                      content-cols-lg="8"
                  >
                      <b-form-input v-model="vendor.username" id="username" />
                  </b-form-group>
                  <b-form-group
                      class="row"
                      :label="$t('forms.password')"
                      label-for="password"
                      label-cols-md="4"
                      content-cols-lg="8"
                  >
                      <b-form-input v-model="vendor.password" id="password" />
                  </b-form-group>
                  <b-form-group
                      class="row"
                      :label="$t('forms.lang')"
                      label-for="lang"
                      label-cols-md="4"
                      content-cols-lg="8"
                  >
                      <b-form-input v-model="vendor.lang" id="lang" />
                  </b-form-group>
                  <b-form-group
                      :class="{ row, error: v$.vendor.company.$errors.length }"
                      :label="$t('forms.company')"
                      label-for="company"
                      label-cols-md="4"
                      content-cols-lg="8"
                  >
                      <b-form-input v-model="vendor.company" id="company" />
                      <div
                          class="input-errors"
                          v-for="error of v$.vendor.company.$errors"
                          :key="error.$uid"
                      >
                          <small class="text-danger">{{ error.$message }}</small>
                      </div>
                  </b-form-group>
                  <b-form-group
                      class="row"
                      :label="$t('forms.streetAddress')"
                      label-for="streetAddress"
                      label-cols-md="4"
                      content-cols-lg="8"
                  >
                      <b-form-input v-model="vendor.address.streetAddress" id="streetAddress" />
                  </b-form-group>
                  <b-form-group
                      class="row"
                      :label="$t('forms.postalCode')"
                      label-for="postalCode"
                      label-cols-md="4"
                      content-cols-lg="8"
                  >
                      <b-form-input v-model="vendor.address.postalCode" id="postalCode" />
                  </b-form-group>
                  <b-form-group
                      class="row"
                      :label="$t('forms.country')"
                      label-for="country"
                      label-cols-md="4"
                      content-cols-lg="8"
                  >
                      <b-form-input v-model="vendor.address.country" id="country" />
                  </b-form-group>
            </b-col>
        </b-row>
    </b-form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import "vue3-date-time-picker/dist/main.css";

export default {
  props: {
    vendorObject: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ResourceUtils, ListUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      vendor: {
        name: { required },
        contactName: { required },
        email: { required, email },
        telephone: { required },
        website: { required },
        company: { required },
      },
    };
  },
  data() {
    return {
      vendor: this.vendorObject,
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        if (this.action === "add") {
          this.create(
            this.$Vendors,
            this.vendor,
            this.$t("messages.vendorCreated"),
            null,
            this.success,
            this.error
          );
        }
        if (this.action === "update") {
          this.$emit("clearAction");
          this.update(
            this.$Vendors,
            this.vendor.id,
            this.vendor,
            this.$t("messages.vendorUpdated"),
            null,
            this.success,
            this.error
          );
        }
      } else {
        this.$emit("clearAction");
      }
    },
    success() {
      this.$router.push(`/vendors`);
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>

